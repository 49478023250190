<template>
  <NuxtI18nLink
    :to="link"
    class="provider-card"
    :class="className"
    data-t="provider-card"
    :data-provider-id="props.provider.id"
  >
    <span class="provider-counter" data-t="provider-counter">
      {{ props.provider?.gamesNumber }}
    </span>
    <StIcon
      v-if="props.provider.icon"
      :svg="props.provider.icon"
      class="provider-icon"
      data-t="provider-icon"
    />
    <div v-else data-t="provider-name-5p39">{{ provider.name }}</div>
  </NuxtI18nLink>
</template>

<script lang="ts" setup>
import type { Provider } from '../../types'

const props = defineProps<{ provider: Provider; mobile?: boolean }>()
const link = computed(() => `/casino/providers/${props.provider.code}`)
const className = computed(() => props.mobile && 'is-mobile')
</script>

<style scoped>
.provider-card {
  cursor: pointer;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-width: 150px;
  height: 80px;

  background: var(--background-primary);
  border-radius: var(--border-radius-150);

  transition: background-color 0.3s ease;

  &:hover {
    background: var(--background-secondary);
  }

  img {
    width: 100%;
  }

  &.is-mobile {
    min-width: 148px;
    height: 64px;
  }
}

.provider-counter {
  position: absolute;
  top: 8px;
  left: 8px;

  display: inline-flex;
  gap: var(--spacing-0);
  align-items: center;
  justify-content: center;

  padding: var(--spacing-025) var(--spacing-100);

  font: var(--desktop-text-xxs-semibold);

  background: var(--background-base, #111118);
  border-radius: var(--border-radius-full, 999px);
}

a {
  all: unset;
}

.provider-icon {
  aspect-ratio: 106/32;
  width: 100%;
  height: auto;
}
</style>
